<template>
  <CContainer class="d-flex align-items-center">
    <CRow class="w-100 justify-content-center">
      <CCol md="6">
        <div class="clearfix">
          <h1 class="float-left display-3 mr-4">
            500
          </h1>
          <h4 class="pt-3">
            Houston, tenemos un problema
          </h4>
          <p class="text-muted">
            La página que estás buscando está temporalmente no funcional.
          </p>
        </div>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import axios from "axios";
import "core-js/stable";
import "regenerator-runtime/runtime";

async function checkToken(token) {
  await axios
    .get(process.env.VUE_APP_URL + process.env.VUE_APP_URL_CHECKTOKEN, {
      headers: {
        Authorization: token,
      },
    })
    .catch(function (error) {
      if (error.response) {
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          window.location.href = "#/pages/login";
        }
        if (error.response.status == 404) {
          window.location = "#/404";
        }
      }
    });
}

export default {
  name: "Page500",
  created() {
    checkToken(localStorage.token);
  },
};
</script>
